import { Navigate,useNavigate } from 'react-router-dom';
import React from 'react'
const Root = () => {
    const navigate = useNavigate();
    const [isAuthenticated, setAuthenticated] = React.useState(() => {
        const token = localStorage.getItem("getauthtoken");
        return token !== null;
    });

    const getRootUrl = () => {
        // let url = 'dashboard/ecommerce';
        // if(isAuthenticated===true){
        //     let url = 'apps/evci/discover';
        //   return url;
        // }else{
        //     let url = 'apps/evci/discover';
        //     return url;
        // }
        let url = 'apps/evci/discover';
        return url;
    };

    const url = getRootUrl();

    return <Navigate to={`/${url}`} />;
};

export default Root;
